/**
 * http错误码
 */
export enum HttpCode {
  SUCCESS = 200,
  UNAUTHORIZED = 401,
  SERVER_ERROR = 500,
  TIME_OUT = 998,
  UNKNOWN = 999,
}

// 业务状态码
export enum BusinessCode {
  SUCCESS = 1,
  UNKNOWN = 500,
  DEAD_LINK = 111 /** 入职采集链接已失效 */,
  SURVEY_STOP = 112 /** 问卷已停止 */,
  NO_PERMISSION = 109 /** 没有权限 */,
  Leave_Time_FAIL = 110 /** 请假时间校验失败 */,
}
/**
 * 错误提示码
 */
export const ErrorMessage: { [key: number]: string } = {
  401: '当前未登录，请先登录',
  500: '网络请求出现问题，请稍后再试',
  998: '数据请求超时，请稍后再试',
  999: '网络请求出现问题，请稍后再试', //默认错误提示
};
export interface IResponseData<T> {
  data?: T;
  msg?: string;
  code: number;
  ret?: number;
  detail?: string;
}
