import NProgress from 'nprogress';

export default new (class ProgressBar {
  progressBarTimeout: NodeJS.Timeout | null = null;
  show() {
    this.progressBarTimeout && clearTimeout(this.progressBarTimeout);
    this.progressBarTimeout = setTimeout(NProgress.start, 64);
  }
  hide() {
    this.progressBarTimeout && clearTimeout(this.progressBarTimeout);
    NProgress.done();
  }
})();
