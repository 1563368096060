import React from 'react';
import { Dropdown, Menu, Space } from '@arco-design/web-react';
import { IconCaretDown, IconPoweroff } from '@arco-design/web-react/icon';
import { useStore } from '@/store';
import LogoImg from '@/assets/logo.png';
import styles from './style/index.module.less';
import { logout } from '@/utils/auth';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import UserAvatar from '@/components/UserAvatar';
import { apiLogout } from '@/api/system';

function Navbar() {
  const {
    member,
    userInfo,
    curOrganization,
    organizationList,
    changeOrganization,
    userLoading,
  } = useStore('user');

  const onLogout = () => {
    logout(false);
  };
  const navigate = useNavigate();
  const onMenuItemClick = async (key: string) => {
    if (key === 'logout') {
      await apiLogout();
      onLogout();
    } else {
    }
  };
  const onOrgMenuClick = (orgId: string) => {
    changeOrganization(orgId, () => {
      navigate(`/org/${orgId}/`);
    });
  };
  const dropList = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        退出登录
      </Menu.Item>
    </Menu>
  );
  const orgDropList = (
    <Menu onClickMenuItem={onOrgMenuClick}>
      {organizationList.map((item) => (
        <Menu.Item key={item.id || ''}>
          &nbsp;&nbsp;{item.name}&nbsp;&nbsp;
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <img src={LogoImg} />
        </div>
      </div>
      <Space className={styles.right} size={'large'}>
        {curOrganization && (
          <Dropdown droplist={orgDropList} position="br" trigger={'click'}>
            <Space size={4} style={{ cursor: 'pointer' }}>
              {curOrganization?.name}
              <IconCaretDown
                style={{ display: 'flex', alignItems: 'center' }}
              />
            </Space>
          </Dropdown>
        )}
        {(!userLoading || userInfo || member) && (
          <Dropdown droplist={dropList} position="br" trigger={'click'}>
            <Space size={4} style={{ cursor: 'pointer' }}>
              <UserAvatar name={member?.name || userInfo?.name || ''} />
              {member?.name || userInfo?.name}
              <IconCaretDown
                style={{ display: 'flex', alignItems: 'center' }}
              />
            </Space>
          </Dropdown>
        )}
      </Space>
    </div>
  );
}

export default observer(Navbar);
