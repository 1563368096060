import { UploadItem } from '@arco-design/web-react/lib/Upload';
import { IAttachment, IMember } from '@/api/typings/common';

/** 取字符串首字母对应的数字  */
export const transName2Num = (str?: string, mod = 6) => {
  if (!str) {
    return 0;
  }
  return Number(str?.charCodeAt(0)?.toString(10)) % mod;
};
export const sleep = async (time: number) => {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

/** 将k-v形式的type-text转成select，option */
export const typeToSelectOptions = <T extends Record<string | number, string>>(
  typeText: T
): { value: keyof T; label: string }[] => {
  return Object.entries(typeText).map(([key, text]) => {
    return { value: +key || key, label: text };
  });
};

export function travelTree<T extends { children?: T[] }>(
  tree: T[],
  callback: (item: T) => void
) {
  const travel = (nodes: T[]) => {
    nodes.forEach((node) => {
      callback(node);
      if (node.children) {
        travel(node.children);
      }
    });
  };
  travel(tree);
  return tree;
}
export function treeToArray<T extends { children?: T[] }>(
  tree: T[],
  keyField = 'id'
) {
  const record: Record<string, T> = {};
  travelTree(tree, (item) => {
    const key = item[keyField as keyof T] as string | number;
    record[key] = item;
  });
  return record;
}
/** 返回新对象，对象的0和空字符串会转成undefined,主要用来转化类型字段，防止在select上展示0 */
export function transEmptyValue<T extends Record<string, any>>(obj: T): T {
  const newForm = {} as T;
  Object.keys(obj || {}).forEach((key) => {
    const k = key as keyof T;
    newForm[k] = (obj?.[k] || undefined) as any;
  });
  return newForm;
}

/** 上传组件数据转成后端附件数据,未上传成功的不返回  */
export const uploadToAttachmentList = (
  uploadItemList: UploadItem[] = []
): IAttachment[] => {
  return uploadItemList
    .filter((item: UploadItem) => item.url)
    .map((item: UploadItem) => ({
      key: item.url,
      name: item.name,
    }));
};

/** 后端附件数据转为上传数组数据类型 */
export const attachmentToUploadList = (
  attachmentList: IAttachment[] = []
): UploadItem[] => {
  return attachmentList.map((item) => {
    return {
      uid: item.key || '',
      name: item.name,
      url: item.key,
    };
  });
};

/** 后端member对象转成select value对象  */

export const transMemberToSelectValue = (member?: IMember | null) => {
  if (!member?.memberId) {
    return;
  }
  return {
    label: member.name,
    value: member.memberId,
  };
};

export const complement = (arrA: any[], arrB: any[]) => {
  const sa = new Set(arrA);
  const sb = new Set(arrB);
  const result = [
    ...arrA.filter((x) => !sb.has(x)),
    ...arrB.filter((x) => !sa.has(x)),
  ];
  return result;
};
export const paramObj = (url: string) => {
  const search = url.split('?')[1];
  if (!search) {
    return new URLSearchParams();
  }
  return new URLSearchParams(search);
};
const MimeTypes: Record<string, string> = {
  '3gp': 'video/3gpp',
  aac: 'audio/x-aac',
  aiff: 'audio/aiff',
  avi: 'video/x-msvideo',
  bmp: 'image/bmp',
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  flash: 'application/x-shockwave-flash',
  gif: 'image/gif',
  gz: 'application/x-gzip',
  html: 'text/html',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  js: 'application/javascript',
  json: 'application/json',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  ogg: 'audio/ogg',
  pdf: 'application/pdf',
  png: 'image/png',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  rar: 'application/x-rar-compressed',
  rtf: 'application/rtf',
  svg: 'image/svg+xml',
  swf: 'application/x-shockwave-flash',
  tar: 'application/x-tar',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  txt: 'text/plain',
  wav: 'audio/x-wav',
  webm: 'video/webm',
  webp: 'image/webp',
  woff: 'application/font-woff',
  woff2: 'application/font-woff2',
  xml: 'text/xml',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  zip: 'application/zip',
};

export const getMIMETypeByFileName = (fileName: string) => {
  const list = fileName?.split('.') ?? [];
  const extension = list?.[list.length - 1]?.toLowerCase();
  return MimeTypes[extension] || 'application/octet-stream';
};
export const isImage = (fileName?: string) => {
  if (!fileName) {
    return false;
  }
  return getMIMETypeByFileName(fileName)?.includes('image/');
};
