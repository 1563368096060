import React, { CSSProperties, useMemo } from 'react';
import EmptyNoData from '@/assets/empty/empty-no-data.png';
import EmptyError from '@/assets/empty/empty-error.png';
import EmptyNoExist from '@/assets/empty/empty-no-exist.png';
import EmptyNoPermission from '@/assets/empty/empty-no-permission.png';
import EmptyBrowser from '@/assets/empty/empty-browser.png';
import EmptyNoResult from '@/assets/empty/empty-no-result.png';
import styles from './index.module.less';

interface IProps {
  type?:
    | 'noData'
    | 'error'
    | 'noExist'
    | 'noPermission'
    | 'browser'
    | 'noResult';
  text?: React.ReactNode;
  size?: number;
  style?: CSSProperties;
  center?: boolean;
  bottom?: React.ReactNode;
}
const Empty = React.memo((props: IProps) => {
  const { type, style, bottom, size = 160, center, text } = props;
  const data = useMemo(() => {
    const map = {
      noData: {
        text: '暂无数据',
        img: EmptyNoData,
      },
      error: {
        text: '抱歉，网络/服务异常，请重试或返回',
        img: EmptyError,
      },
      noExist: {
        text: '内容不存在',
        img: EmptyNoExist,
      },
      noPermission: {
        text: '无权限',
        img: EmptyNoPermission,
      },
      browser: {
        text: '已经在外部浏览器打开该页面',
        img: EmptyBrowser,
      },
      noResult: {
        text: '结果为空',
        img: EmptyNoResult,
      },
      default: {
        text: '暂无数据',
        img: EmptyNoData,
      },
    };
    return type ? map[type] : map.noData;
  }, [type]);
  return (
    <div
      className={styles.empty}
      style={{
        height: center ? '100%' : 'auto',
        ...style,
      }}
    >
      {center && <div style={{ flex: 2 }} />}
      <img width={size} height={size} src={data?.img} />
      <div>{text || data?.text}</div>
      {bottom}
      {center && <div style={{ flex: 3 }} />}
    </div>
  );
});
export default Empty;
