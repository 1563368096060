import { makeAutoObservable, runInAction } from 'mobx';
import { IPermissionKV, PermissionCode } from '@/utils/auth/config';
import { IUserInfo } from '@/api/typings/account';
import { LocalStorageKey } from '@/constants/storage';
import { Message } from '@arco-design/web-react';
import { apiGetPermissions } from '@/api/menu';
import { IMember } from '@/api/typings/common';
import { apiGetOrganizationList } from '@/api/system';
import { IOrg } from '@/api/typings/system';
import { getOrg } from '@/utils/hooks/useOrg';
import { sleep } from '@/utils/tools';
import { AccountStatus } from '@/constants/business/people';

export default class User {
  userInfo: IUserInfo | undefined; // 尽量不用
  member: IMember | undefined;
  isAdmin = false;
  curOrganization: IOrg | undefined;
  organizationList: IOrg[] = [];
  userLoading = true;
  permissions: PermissionCode[] = [];
  mobileTail = '';
  accountStatus: AccountStatus | undefined;
  permissionKV: IPermissionKV = {};
  isLeader = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setPermissionKV(permission: IPermissionKV) {
    this.permissionKV = permission;
  }

  setMember(member?: IMember) {
    this.member = member;
  }
  setIsAdmin(isAdmin: boolean) {
    this.isAdmin = isAdmin;
  }
  setIsLeader(isLeader: boolean) {
    this.isLeader = isLeader;
  }
  setMobileTail(mobileTail: string) {
    this.mobileTail = mobileTail;
  }
  setAccountStatus(accountStatus?: AccountStatus) {
    this.accountStatus = accountStatus;
  }
  setOrganizationList(organizationList: IOrg[]) {
    this.organizationList = organizationList;
  }

  setOrganizationDetail(orgId: string) {
    this.curOrganization = this.organizationList.find(
      (item) => item.id === orgId
    );
    localStorage.setItem(LocalStorageKey.ORGANIZATION_ID, orgId);
  }

  async fetchPermissionList() {
    const {
      list,
      member,
      isAdmin,
      mobileTail = '',
      accountStatus,
      isLeader = false,
    } = await apiGetPermissions();
    const record: IPermissionKV = {};
    list?.forEach((item) => {
      record[item.code] = item;
    });
    this.setPermissionKV(record);
    this.setMember(member);
    this.setIsAdmin(isAdmin);
    this.setMobileTail(mobileTail);
    this.setAccountStatus(accountStatus);
    this.setIsLeader(isLeader);
  }
  async changeOrganization(orgId: string, callback?: () => void) {
    this.userLoading = true;
    this.setOrganizationDetail(orgId);
    callback?.();
    await Promise.all([sleep(500), this.fetchPermissionList()]);
    runInAction(() => {
      this.userLoading = false;
    });
  }

  async queryUserInfo() {
    this.userLoading = true;
    try {
      const { orgId } = getOrg();

      if (orgId) {
        const { list = [] } = await apiGetOrganizationList({ orgId: orgId });
        this.setOrganizationList(list);
        this.setOrganizationDetail(orgId);

        await Promise.all([sleep(500), this.fetchPermissionList()]);
        runInAction(() => {
          this.userLoading = false;
        });
      } else {
        Message.error('组织不存在');
        runInAction(() => {
          this.userLoading = false;
        });
      }
    } catch (e) {
      runInAction(() => {
        this.userLoading = false;
      });
      console.error(e);
    }
  }

  clear() {
    this.userInfo = undefined;
    this.curOrganization = undefined;
  }
}
