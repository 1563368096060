import { BusinessCode, ErrorMessage, IResponseData } from '@/http/config';

/**
 * 抽象类
 */
export abstract class CommonError extends Error {
  protected _code: number;

  protected constructor(code: number, message: string) {
    super(message);
    this._code = code;
  }

  abstract get code(): number;
}

/**
 * http 网络错误类
 */
export class HttpError extends CommonError {
  constructor(code: number, message = '') {
    super(code, message);
  }

  get code() {
    return this._code;
  }
}

export class BusinessError extends CommonError {
  constructor(code: number, message = '') {
    super(code, message);
  }

  get code() {
    return this._code;
  }
}
/** 新旧接口转化成{data:T,code,msg} */
export function transRes(responseData: any): IResponseData<any> {
  const { code, message, ret, detail, data } = responseData;
  if (ret) {
    return {
      code: ret,
      msg: detail,
      data: data,
    };
  } else {
    if (code) {
      /** 有code证明发生业务错误 */
      return { code: code, msg: message || ErrorMessage[BusinessCode.UNKNOWN] };
    } else {
      /** 没有code证明未发生错误，data是responseData */
      return { code: BusinessCode.SUCCESS, data: responseData };
    }
  }
}
