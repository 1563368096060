import { Toast } from '@arco-design/mobile-react';
import { ToastProps } from '@arco-design/mobile-react/esm/toast';
import { ToastType } from '@arco-design/mobile-react/cjs/toast';

export default class MToast {
  static show(type: ToastType | 'info' | 'toast', options: ToastProps) {
    // @ts-ignore
    if (!!window.toastInstance) {
      // @ts-ignore
      window.toastInstance.close();
    }
    // @ts-ignore
    window.toastInstance = Toast[type](
      {
        ...options,
      },
      { prefixCls: 'arco-mobile' }
    );
  }
  static hide() {
    // @ts-ignore
    if (!!window.toastInstance) {
      // @ts-ignore
      window.toastInstance.close();
    }
  }
  static loading() {
    // @ts-ignore
    if (!!window.loadingInstance) {
      // @ts-ignore
      window.loadingInstance.close();
    }
    // @ts-ignore
    window.loadingInstance = Toast.toast(
      {
        loading: true,
      },
      { prefixCls: 'arco-mobile' }
    );
  }
  static hideLoading() {
    // @ts-ignore
    if (!!window.loadingInstance) {
      // @ts-ignore
      window.loadingInstance.close();
    }
  }
}
