import lazyLoad from '@/utils/lazyLoad';
import { IRoute } from '@/router/typings';

const baseRoutes: IRoute[] = [
  {
    name: '请假个人申请',
    path: '/leave/apply',
    component: lazyLoad(() => import('@/pages/mobilePage/leave/LeaveApply')),
  },
  {
    name: '请假个人申请',
    path: '/leave/apply/:leaveId',
    component: lazyLoad(() => import('@/pages/mobilePage/leave/LeaveApply')),
  },
  {
    name: '休假记录',
    path: '/leave/manage',
    component: lazyLoad(() => import('@/pages/mobilePage/leave/LeaveList')),
  },
  {
    name: '休假详情',
    path: '/leave/manage/detail/:id',
    component: lazyLoad(() => import('@/pages/mobilePage/leave/LeaveDetail')),
  },
  {
    name: '离职申请',
    path: '/resign/apply',
    component: lazyLoad(
      () => import('@/pages/mobilePage/resign/MobileResignApply')
    ),
  },
  {
    name: '离职申请记录',
    path: '/resign/list',
    component: lazyLoad(
      () => import('@/pages/mobilePage/resign/MobileResignList')
    ),
  },
  {
    name: '人事申请-离职审批详情',
    path: '/people/staff-apply/resign/:id',
    component: lazyLoad(
      () => import('@/pages/mobilePage/resign/MobileResignDetail')
    ),
    ignore: true,
  },
  {
    name: '离职管理-离职审批详情',
    path: '/people/resign/apply/:id',
    component: lazyLoad(
      () => import('@/pages/mobilePage/resign/MobileResignDetail')
    ),
    ignore: true,
  },
  {
    name: '人事申请-在职信息采集详情',
    path: '/people/staff-apply/collect/:id',
    component: lazyLoad(
      () => import('@/pages/mobilePage/collect/MobileCollectApplyDetail')
    ),
  },
  {
    name: '花名册-在职信息采集详情',
    path: '/people/roster/collect/:id',
    component: lazyLoad(
      () => import('@/pages/mobilePage/collect/MobileCollectApplyDetail')
    ),
  },
  {
    name: '我的考勤',
    path: '/attendance/my-attendance',
    component: lazyLoad(
      () => import('@/pages/mobilePage/attendance/MobileAttendanceTransfer')
    ),
  },
  {
    name: '补卡',
    path: '/attendance/my-attendance/replace-card-apply',
    component: lazyLoad(
      () => import('@/pages/mobilePage/attendance/MobileReplaceCardApply')
    ),
  },
  {
    name: '我的补卡记录',
    path: '/attendance/my-replace-card',
    component: lazyLoad(
      () => import('@/pages/mobilePage/attendance/MobileMyReplaceCardList')
    ),
  },
  {
    name: '补卡详情',
    path: '/people/staff-apply/collect/:id',
    component: lazyLoad(
      () => import('@/pages/mobilePage/collect/MobileCollectApplyDetail')
    ),
  },
  {
    name: '花名册',
    path: '/people/roster',
    component: lazyLoad(() => import('@/pages/mobilePage/Roster')),
  },
  {
    name: '加班申请详情',
    path: '/attendance/overtime/detail/:id',
    component: lazyLoad(() => import('@/pages/mobilePage/defaultPage')),
  },
];
export default baseRoutes;
