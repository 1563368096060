import { ICommonConfig } from '@/api/typings/system';
import { makeAutoObservable } from 'mobx';
import { apiGetSystemConfig } from '@/api/system';
import { SystemConfigType } from '@/constants/business/system';

export default class SystemConfig {
  commonConfig: ICommonConfig = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setCommonConfig(commonConfig: ICommonConfig) {
    this.commonConfig = commonConfig;
  }

  async queryCommonConfig() {
    try {
      const { config } = await apiGetSystemConfig({
        type: SystemConfigType.COMMON,
      });
      if (config?.common) {
        this.setCommonConfig(config?.common);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
