import { observer } from 'mobx-react-lite';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { checkLogin, redirectToLogin } from '@/utils/auth';
import { useStore } from './store';
import { Loading } from '@arco-design/mobile-react';

const MobileLayout = () => {
  const location = useLocation();
  const { userLoading, queryUserInfo } = useStore('user');

  useEffect(() => {
    if (checkLogin()) {
      queryUserInfo();
    } else if (location.pathname.replace(/\//g, '') !== 'login') {
      redirectToLogin();
    }
  }, []);

  return (
    <div className={'ehr-mobile'}>
      {userLoading ? (
        <div
          style={{
            display: 'flex',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading type="dot" />
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default observer(MobileLayout);
