import React, { useEffect, useMemo, useState } from 'react';
import { IRoute } from '@/router/typings';
import { useStore } from '@/store';
import { checkPermission } from '@/utils/auth';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { useMemoizedFn } from 'ahooks';
import PageLayout from '@/layout';
import baseRoutes from './routes';
import baseMobileRoutes from './routes/mobile';
import Exception404 from '@/pages/exception/404';
import { observer } from 'mobx-react-lite';
import ExceptionNoPermission from '@/pages/exception/ExceptionNoPermission';
import lazyLoad from '@/utils/lazyLoad';
import * as dd from 'dingtalk-jsapi';
import DingDingTransfer from '@/pages/middle/DingDingTransfer';
import OldRouteTransfer from '@/pages/middle/OldRouteTransfer';
import { getOrg } from '@/utils/hooks/useOrg';

const isMobile = Device.isMobile();

const ProposalSuccess = lazyLoad(
  () => import('@/pages/collect/ProposalSuccess')
);
const Proposal = lazyLoad(() => import('@/pages/collect/Proposal'));
const ProposalCollect = lazyLoad(
  () => import('@/pages/collect/ProposalCollect')
);
import MobileLayout from '@/mobileLayout';
import MobileLogin from '@/pages/mobilePage/MobileLogin';
import Login from '@/pages/login';
import Device from '@/utils/device';

const MobileEntryCollect = lazyLoad(
  () => import('@/pages/mobilePage/collect/MobileEntryCollect')
);
const Collect = lazyLoad(() => import('@/pages/collect/Collect'));

/** 返回所有有权限的route列表，和默认路由 */
export const useAuthRoute = () => {
  const { permissionKV, member } = useStore('user');
  const filterRoute = useMemoizedFn((routes: IRoute[], prefix = '') => {
    /** 打平的最下级路由列表(没有igonre),只有下级路由才需要渲染页面 */
    const menuRoutes: IRoute[] = [];
    const travel = (
      _routes: IRoute[],
      arr: IRoute[] = [],
      prefix = ''
    ): IRoute[] => {
      if (!_routes.length) {
        return [];
      }
      for (const route of _routes) {
        const { permission } = route;
        let visible = true;
        /** 用户不存在时显示无权限缺省页 */
        if (!member) {
          visible = false;
        } else if (permission) {
          visible = checkPermission(permission, permissionKV);
        }
        if (visible) {
          /** 生成侧边菜单子路由(只有子路由才可以点击) */
          const menuChildren = (route.children || []).filter(
            (child) => !child.ignore
          );

          if (route.component && (!route.children || !menuChildren.length)) {
            menuRoutes.push({
              ...route,
              path: `${route.path?.replace('/', '')}`,
            });
          }
        }
        /** 生成router 路由表，无权限的路由component设置为无权限缺省页 */
        if (route.children && route.children.length) {
          const newRoute = {
            ...route,
            path: `${prefix}${route.path}`,
            ignore: visible ? route.ignore : true,
            component: visible ? route.component : ExceptionNoPermission,
            children: [] as IRoute[],
          };
          travel(route.children, newRoute.children, prefix);
          if (newRoute.children.length) {
            arr.push(newRoute);
          }
        } else {
          arr.push({
            ...route,
            path: prefix + route.path,
            ignore: visible ? route.ignore : true,
            component: visible ? route.component : ExceptionNoPermission,
          });
        }
      }
      return arr;
    };
    const authRoutes = travel(routes, [], prefix);
    return { authRoutes, menuRoutes };
  });

  const [permissionRoutes, setPermissionRoutes] = useState<IRoute[]>([]);
  /** 打平的最深一级的路由列表 */
  const [dpMenuRoutes, setDpMenuRoutes] = useState<IRoute[]>([]);
  const userPermissionJson = JSON.stringify(permissionKV);

  useEffect(() => {
    const { authRoutes, menuRoutes } = filterRoute(
      isMobile ? baseMobileRoutes : baseRoutes,
      '/org/:orgId'
    );
    setPermissionRoutes(authRoutes);
    setDpMenuRoutes(menuRoutes);
  }, [userPermissionJson, member]);

  const defaultRoutePath = useMemo(() => {
    const first = dpMenuRoutes.find((item) => !item.ignore && item.component);
    if (first) {
      return first?.path;
    }
    return '';
  }, [dpMenuRoutes]);
  return {
    permissionRoutes,
    defaultRoutePath,
    dpMenuRoutes,
  };
};

/** 转换成react-router要求的格式 */
const transRoutes = (routes: IRoute[]): RouteObject[] => {
  const mRouteTable: RouteObject[] = [];
  routes.forEach((route) => {
    const Element = route.component;
    mRouteTable.push({
      path: route.path,
      element: Element ? <Element /> : null,
      children: route.children && transRoutes(route.children),
    });
  });
  return mRouteTable;
};
export const transRoutePathToKey = (path: string) => {
  const key = path.replace(/\/org\/[^\/]*\//, '');
  return key;
};
const MainRouter = observer(() => {
  const { permissionRoutes, defaultRoutePath } = useAuthRoute();
  const routeTable = useMemo(() => {
    return transRoutes(permissionRoutes);
  }, [permissionRoutes]);
  // const [searchParams, setSearchParams] = useSearchParams();
  // const orgId = searchParams.get('org');
  // useEffect(() => {
  //   if (orgId && Pattern.ORG_ID.test(orgId)) {
  //     localStorage.setItem(LocalStorageKey.ORGANIZATION_ID, orgId);
  //     /** 钉钉打开的不需要去掉org */
  //     if (dd.env.platform === 'notInDingTalk') {
  //       searchParams.delete('org');
  //       setSearchParams(searchParams);
  //     }
  //   }
  // }, [orgId]);
  const { orgId } = getOrg();
  if (
    location.hostname === 'survey.ixiaochuan.cn' ||
    location.hostname === 'proposal.ixiaochuan.cn'
  ) {
    return useRoutes([
      {
        path: '/feedback',
        element: null,
        children: [
          {
            path: '/feedback/',
            element: <Proposal />,
            children: [
              {
                path: '/feedback/sc',
                element: <ProposalSuccess />,
              },
              {
                path: '/feedback/:key',
                element: <ProposalCollect />,
              },
            ],
          },
        ],
      },
    ]);
  }

  if (isMobile) {
    return useRoutes([
      {
        path: '/feedback/',
        element: <Proposal />,
        children: [
          {
            path: '/feedback/sc',
            element: <ProposalSuccess />,
          },
          {
            path: '/feedback/:key',
            element: <ProposalCollect />,
          },
        ],
      },
      {
        path: '/collect',
        element: null,
        children: [
          {
            path: '/collect/:uniqueCode',
            element: <MobileEntryCollect />,
          },
        ],
      },
      orgId
        ? {
            path: '/org/:orgId',
            children: [
              {
                path: '/org/:orgId/login',
                element: <MobileLogin />,
              },
              {
                path: '/org/:orgId/',
                element: <MobileLayout />,
                children: [
                  {
                    path: '/org/:orgId/',
                    element: defaultRoutePath ? (
                      <Navigate replace to={`${defaultRoutePath}`} />
                    ) : null,
                  },
                  ...routeTable,
                  {
                    path: '*',
                    element: <Exception404 />,
                  },
                  // {
                  //   path: 'transfer',
                  //   element: <DingDingTransfer />,
                  // },
                  // {
                  //   path: '*',
                  //   element: (
                  //     <Navigate
                  //       replace
                  //       to={`transfer?redirect=${encodeURIComponent(
                  //         window.location.href
                  //       )}`}
                  //     />
                  //   ),
                  // },
                ],
              },
            ],
          }
        : {
            path: '/org/:orgId/*',
            element: <Exception404 />,
          },
    ]);
  }
  if (dd.env.platform !== 'notInDingTalk') {
    return useRoutes([
      {
        path: '/transfer',
        element: <DingDingTransfer />,
      },
      {
        path: '*',
        element: (
          <Navigate
            replace
            to={`/transfer?redirect=${encodeURIComponent(
              window.location.href
            )}`}
          />
        ),
      },
    ]);
  }
  return useRoutes([
    {
      path: '/feedback',
      element: null,
      children: [
        {
          path: '/feedback/',
          element: <Proposal />,
          children: [
            {
              path: '/feedback/sc',
              element: <ProposalSuccess />,
            },
            {
              path: '/feedback/:key',
              element: <ProposalCollect />,
            },
          ],
        },
      ],
    },
    {
      path: '/collect',
      element: null,
      children: [
        {
          path: '/collect/:uniqueCode',
          element: <Collect />,
        },
      ],
    },
    orgId
      ? {
          path: '/org/:orgId',
          children: [
            {
              path: '/org/:orgId/login',
              element: <Login />,
            },
            {
              path: '/org/:orgId/',
              element: <PageLayout />,
              children: [
                ...routeTable,
                {
                  path: '/org/:orgId/',
                  element: defaultRoutePath ? (
                    <Navigate replace to={`${defaultRoutePath}`} />
                  ) : (
                    <ExceptionNoPermission />
                  ),
                },
                {
                  path: '*',
                  element: <Exception404 />,
                },
              ],
            },
          ],
        }
      : {
          path: '/org/:orgId/*',
          element: <Exception404 />,
        },

    {
      path: '/404',
      element: <Exception404 />,
    },
    {
      path: '*',
      element: <OldRouteTransfer />,
    },
  ]);
});
export default MainRouter;
