import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Loading } from '@arco-design/mobile-react';
import { BusinessCode } from '@/http/config';
import Empty from '@/components/Empty';
import * as dd from 'dingtalk-jsapi';
import { ContextProvider } from '@arco-design/mobile-react';
import styles from './index.module.less';
interface IMobilePageProps {
  title: string;
  pageLoading?: boolean;
  errorCode?: BusinessCode;
}
const MobilePage = (props: React.PropsWithChildren<IMobilePageProps>) => {
  const { title, pageLoading, errorCode } = props;

  useEffect(() => {
    if (dd.env.platform !== 'notInDingTalk') {
      dd.ready(function () {
        dd.biz.navigation.setTitle({
          title: title, //控制标题文本，空字符串表示显示默认文本
        });
      });
    }
  }, [title]);
  const errConfig = useMemo(() => {
    if (!errorCode) {
      return;
    }
    let type;
    let text;
    if (errorCode === BusinessCode.NO_PERMISSION) {
      type = 'noPermission';
    } else if (errorCode === BusinessCode.DEAD_LINK) {
      type = 'noExist';
      text = '链接已失效';
    } else {
      type = 'noExist';
    }
    return {
      type: type as any,
      text: text,
    };
  }, [errorCode]);
  return (
    <ContextProvider prefixCls={'arco-mobile'}>
      <div className={styles.mobilePage}>
        <Helmet>
          <meta charSet="UTF-8" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
          />
          <meta name="format-detection" content="telephone=no" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <title>{title}</title>
        </Helmet>
        {pageLoading ? (
          <div
            style={{
              display: 'flex',
              height: '100vh',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            <Loading type="dot" />
          </div>
        ) : errorCode ? (
          <div style={{ height: '80vh' }}>
            <Empty type={errConfig?.type} text={errConfig?.text} center />
          </div>
        ) : (
          props.children
        )}
      </div>
    </ContextProvider>
  );
};

export default MobilePage;
