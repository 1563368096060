//配置类型 1:入职绩效 2、离职
export enum SystemConfigType {
  SPECIAL = 1, //特殊人员配置
  RESIGN = 2, //离职人员配置
  COMMON = 3, //通用配置
  REGULAR = 4, //转正特殊人员配置
}
export enum SystemConfigCustomType {
  DING_DING = 'DING_DING',
}
//节假日类型 1:国家法定 2:自定义
export enum HolidayType {
  National = 1, //国家法定

  Custom = 2, //自定义
}
//节假日操作 1:放假 2:加班
export enum HolidayOperation {
  Holiday = 1, //放假

  Work = 2, //加班
}
