import { BusinessCode, ErrorMessage, HttpCode, IResponseData } from './config';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BusinessError, HttpError, transRes } from '@/http/utils';
import { logout } from '@/utils/auth';
import { LocalStorageKey } from '@/constants/storage';
import { getOrg } from '@/utils/hooks/useOrg';

const axiosInstance = axios.create({
  timeout: 15000,
  withCredentials: true,
});

// http request 拦截器
axiosInstance.interceptors.request.use(
  (axiosRequestConfig: AxiosRequestConfig) => {
    let { orgId = '' } = getOrg();
    if (!orgId) {
      orgId = localStorage.getItem(LocalStorageKey.ORGANIZATION_ID) || '';
    }
    const token = localStorage.getItem(LocalStorageKey.TOKEN) || '';
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      h_mb_token: token,
      organization_id: orgId,
    };
    axiosRequestConfig.headers = {
      ...headers,
      ...axiosRequestConfig.headers,
    };
    return axiosRequestConfig;
  },
  (error: AxiosError) => {
    const code = HttpCode.UNKNOWN;
    return Promise.reject(new HttpError(code, error.message));
  }
);

// http response 拦截器
axiosInstance.interceptors.response.use(
  (response: AxiosResponse<IResponseData<any>>) => {
    const { status, data: resData } = response;
    const responseData = transRes(resData);
    if (status === HttpCode.SUCCESS) {
      const { code, msg } = responseData;
      switch (code) {
        case BusinessCode.SUCCESS:
          return Promise.resolve(responseData);
        default:
          return Promise.reject(new BusinessError(code, msg));
      }
    } else if (status === HttpCode.UNAUTHORIZED) {
      // 如果登录过期或者未登录
      logout();
      return Promise.reject(new HttpError(status, ''));
    } else {
      return Promise.reject(
        new HttpError(
          status,
          ErrorMessage[status] || ErrorMessage[HttpCode.SERVER_ERROR]
        )
      );
    }
  },
  (error: AxiosError) => {
    const status = error.response?.status;
    let code = status || HttpCode.UNKNOWN;
    // 超时
    if (error.code === 'ECONNABORTED' || error.message.includes('timeout')) {
      code = HttpCode.TIME_OUT;
    }
    let message = error?.message || ErrorMessage[code];
    if (code === HttpCode.UNAUTHORIZED) {
      message = '';
      logout();
    }

    return Promise.reject(new HttpError(code, message));
  }
);

export default axiosInstance;
