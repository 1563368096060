import Http from '@/http';
import {
  IContractBody,
  IDataConfig,
  IDataDetailConfig,
  IDingConfig,
  IGetSystemConfigResponse,
  IHolidayResponse,
  ILeavePlanDetail,
  ILeavePlanInfo,
  IOperationLogSearch,
  IOrg,
  ISearchContractBody,
  ISearchLeavePlan,
  ISystemConfig,
} from '@/api/typings/system';
import { SystemConfigType } from '@/constants/business/system';
import {
  ISendSmsCodeParams,
  ISendSmsCodeResponse,
} from '@/api/typings/account';
import { PagedResponse } from '@/api/typings/common';

export function apiGetSystemConfig(params: { type: SystemConfigType }) {
  return Http.get<{ type: SystemConfigType }, IGetSystemConfigResponse>(
    '/hr/system/config/get',
    params
  );
}

export function apiSaveSystemConfig(params: { config: ISystemConfig }) {
  return Http.post<{ config: ISystemConfig }, void>(
    '/hr/system/config/save',
    params
  );
}
export function apiGetHolidayList(params: { year: number }) {
  return Http.get<{ year: number }, IHolidayResponse>(
    '/hr/system/holiday/list',
    params
  );
}
interface ILoginParams {
  code?: string;
  mobile?: string;
  orgId?: string;
}
export function apiLogin(params: ILoginParams) {
  return Http.post<ILoginParams, { token: string }>('/hr/system/login', params);
}

export function apiLogout() {
  return Http.get<void, void>('/hr/system/logout');
}
export function apiSendSmsCode(params: ISendSmsCodeParams) {
  return Http.post<ISendSmsCodeParams, ISendSmsCodeResponse>(
    '/hr/system/send_sms_code',
    params
  );
}
export function apiGetOrganizationList(params: { orgId: string }) {
  return Http.get<{ orgId: string; domain: string }, { list: IOrg[] }>(
    '/hr/system/organization/list',
    {
      ...params,
      domain: window.location.origin,
    }
  );
}

export function apiGetAllLeavePlan() {
  return Http.get<
    void,
    {
      list?: ILeavePlanInfo[];
    }
  >('/hr/system/leave_plan/all');
}
export function apiGetLeavePlanDetail(params: { id: number }) {
  return Http.get<
    { id: number },
    {
      info?: ILeavePlanDetail;
    }
  >('/hr/system/leave_plan/get', params);
}
interface ISearchContractBodyParams {
  leavePlanId?: number[];
  /**
   * 合同主体名称
   */
  name?: string;
  /**
   * 当前页数
   */
  page?: number;
  /**
   * 每页数量
   */
  size?: number;
}
// 搜索合同主体
export function apiSearchContractBody(params: ISearchContractBodyParams) {
  return Http.post<
    ISearchContractBodyParams,
    PagedResponse<ISearchContractBody>
  >('/hr/system/contract_body/search', params);
}
// 保存合同主体
export function apiSaveContractBody(params: {
  info: Partial<IContractBody>;
  leavePlanId?: number;
}) {
  return Http.post<{ info: Partial<IContractBody> }, void>(
    '/hr/system/contract_body/save',
    params
  );
}
// 删除合同主体
export function apiDeleteContractBody(params: { id: number }) {
  return Http.post<{ id: number }, void>(
    '/hr/system/contract_body/delete',
    params
  );
}
interface ISearchLeavePlanParams {
  /**
   * 合同主体名称
   */
  name?: string;
  /**
   * 当前页数
   */
  page?: number;
  /**
   * 每页数量
   */
  size?: number;
}
// 搜索假勤方案
export function apiSearchSystemLeavePlan(params: ISearchLeavePlanParams) {
  return Http.post<ISearchLeavePlanParams, PagedResponse<ISearchLeavePlan>>(
    '/hr/system/leave_plan/search',
    params
  );
}
// 保存假勤方案
export function apiSaveSystemLeavePlan(params: { info: ILeavePlanDetail }) {
  return Http.post<{ info: ILeavePlanDetail }, void>(
    '/hr/system/leave_plan/save',
    params
  );
}
// 删除假勤方案
export function apiDeleteSystemLeavePlan(params: { id: number }) {
  return Http.post<{ id: number }, void>(
    '/hr/system/leave_plan/delete',
    params
  );
}
/** 数据配置搜索 */
interface ISearchDataConfigParams {
  key?: string;
  /**
   * 当前页数
   */
  page?: number;
  /**
   * 每页数量
   */
  size?: number;
}
export function apiSearchDataConfig(params: ISearchDataConfigParams) {
  return Http.post<ISearchDataConfigParams, PagedResponse<IDataConfig>>(
    '/hr/system/data_config/search',
    params
  );
}
/** 获取数据配置详情列表 */
interface IGetDataConfigDetailListParams {
  dataCode?: string;
  isAll?: boolean;
}
export function apiSearchDataConfigDetailList(
  params: IGetDataConfigDetailListParams
) {
  return Http.get<
    IGetDataConfigDetailListParams,
    {
      list: IDataDetailConfig[];
    }
  >('/hr/system/data_config/detail_list', params);
}
export function apiSaveDataConfigDetail(params: {
  info: Partial<IDataDetailConfig>;
}) {
  return Http.post<{ info: Partial<IDataDetailConfig> }, void>(
    '/hr/system/data_config/detail/save',
    params
  );
}
export function apiDeleteDataConfigDetail(params: { id: number }) {
  return Http.post<{ id: number }, void>(
    '/hr/system/data_config/detail/delete',
    params
  );
}
interface ISortDataConfigDetailParams {
  /**
   * code
   */
  dataCode?: string;
  ids?: number[];
}

export function apiSortDataConfigDetail(params: ISortDataConfigDetailParams) {
  return Http.post<ISortDataConfigDetailParams, void>(
    '/hr/system/data_config/detail/sort',
    params
  );
}
export function apiGetSystemDingConfig() {
  return Http.get<
    void,
    {
      config: IDingConfig;
    }
  >('/hr/system/ding_config/get');
}
export function apiSaveSystemDingConfig(params: { config: IDingConfig }) {
  return Http.post<{ config: IDingConfig }, void>(
    '/hr/system/ding_config/save',
    params
  );
}
export interface ISearchOperationParams {
  /**
   * 员工ids
   */
  accountId?: number[];
  /**
   * code
   */
  code?: string[];
  /**
   * 结束时间
   */
  endTime?: number;
  /**
   * 当前页数
   */
  page?: number;
  /**
   * 每页数量
   */
  size?: number;
  /**
   * 开始时间
   */
  startTime?: number;
  /**
   * 类型
   */
  codeKey?: string;
  typeKey?: string;
}

export function apiSearchOperation(params: ISearchOperationParams) {
  return Http.post<ISearchOperationParams, PagedResponse<IOperationLogSearch>>(
    '/hr/operation_log/search',
    params
  );
}
export function apiDingdingLogin(params: { code: string }) {
  return Http.get<
    { code: string },
    {
      token: string;
    }
  >('/hr/system/auto_login', params);
}
