import React, { useMemo } from 'react';
import { Avatar } from '@arco-design/web-react';
import { transName2Num } from '@/utils/tools';
import { AvatarProps } from '@arco-design/web-react/es/Avatar/interface';

interface IProps extends AvatarProps {
  name: string;
}
const UserAvatar = React.memo((props: IProps) => {
  const { name, style, ...other } = props;
  const avatarColor = useMemo(() => {
    const color = [
      '#33D1C9',
      '#F99057',
      '#57A9FB',
      '#8D4EDA',
      '#F9CC45',
      '#B5E241',
    ];
    return color[transName2Num(name)];
  }, [name]);
  return (
    <Avatar
      size={32}
      style={{ backgroundColor: avatarColor, ...style }}
      {...other}
    >
      &nbsp;{name}&nbsp;
    </Avatar>
  );
});
export default UserAvatar;
