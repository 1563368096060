import { IPermission } from '@/api/typings/menu';

// 权限码枚举
export enum PermissionCode {
  PEOPLE = '101', // 人事管理

  ROSTER = '10101', // 花名册
  ROSTER_LIST = '1010101', // 查看列表
  ROSTER_ADD_MEMBER = '1010102', // 添加人员
  ROSTER_EXPORT = '1010103', // 导出花名册
  ROSTER_DETAIL = '1010104', // 查看详情
  ROSTER_DETAIL_EDIT = '1010105', // 编辑详情
  ROSTER_COLLECT = '1010106', // 发起信息采集
  ROSTER_IMPORT = '1010107', // 导入

  ENTRY = '10102', // 入职管理
  ENTRY_LIST = '1010201', // 查看列表
  ENTRY_ADD_MEMBER = '1010202', // 添加待入职人员
  ENTRY_EXPORT = '1010203', // 导出
  ENTRY_LAUNCH_COLLECTION = '1010204', // 发起信息采集
  ENTRY_EDIT_MEMBER = '1010205', // 编辑入职信息
  ENTRY_CANCEL_ENTRY = '1010206', // 取消入职
  ENTRY_DETAIL = '1010207', // 查看详情
  ENTRY_DETAIL_EDIT = '1010208', // 编辑详情
  ENTRY_CONFIRM_ENTRY = '1010209', // 确认入职

  RESIGN = '10103', //离职管理
  RESIGN_LIST = '1010301', //查看列表
  RESIGN_APPLY = '1010302', //离职申请
  RESIGN_DIRECT_RESIGN = '1010303', //离职
  RESIGN_BATCH_RESIGN = '1010304', //批量离职
  RESIGN_MEMBER_DETAIL = '1010305', //查看员工详情
  RESIGN_MEMBER_DETAIL_EDIT = '1010306', // 编辑员工详情
  RESIGN_MEMBER_DETAIL_EXPORT = '1010307', // 导出

  REGULAR = '10104', //转正管理
  REGULAR_LIST = '1010401', //查看列表
  REGULAR_APPLY = '1010402', //转正申请
  REGULAR_DIRECT = '1010403', //转正
  REGULAR_BATCH_APPLY = '1010404', //批量转正申请
  REGULAR_BATCH_DIRECT = '1010405', //批量转正
  REGULAR_MEMBER_DETAIL = '1010406', //查看员工详情
  REGULAR_MEMBER_DETAIL_EDIT = '1010407', // 编辑员工详情

  TRANSFER = '10105', //调动管理
  TRANSFER_LIST = '1010501', //查看列表
  TRANSFER_APPLY = '1010502', //调动申请
  TRANSFER_DIRECT = '1010503', //调动
  TRANSFER_BATCH_APPLY = '1010504', //批量调动申请
  TRANSFER_BATCH_DIRECT = '1010505', //批量调动
  TRANSFER_MEMBER_DETAIL = '1010506', //查看员工详情
  TRANSFER_MEMBER_DETAIL_EDIT = '1010507', // 编辑员工详情

  BLACKLIST = '10106', // 兼岗管理
  BLACKLIST_ADD = '1010601', // 新增黑名单
  BLACKLIST_EDIT = '1010602', // 编辑
  BLACKLIST_DELETE = '1010603', // 移除
  BLACKLIST_LIST = '1010604', // 移除

  ORG = '201', // 组织管理

  DEPARTMENT = '20101', // 部门管理
  DEPARTMENT_LIST = '2010101', // 部门列表
  DEPARTMENT_ADD = '2010102', // 新建部门
  DEPARTMENT_EDIT = '2010103', // 编辑
  DEPARTMENT_ADD_SUB = '2010104', // 添加子部门
  DEPARTMENT_DELETE = '2010105', // 查看详情

  JOB = '20102', // 岗位管理
  JOB_LIST = '2010201', // 查看列表
  JOB_ADD = '2010202', // 新建岗位
  JOB_EDIT = '2010203', // 编辑
  JOB_ON_OFF = '2010204', // 启用/停用
  JOB_DELETE = '2010205', // 删除

  RANK = '20103', // 职级管理
  RANK_LIST = '2010301', // 查看列表
  RANK_ADD = '2010302', // 新建岗位
  RANK_EDIT = '2010303', // 编辑
  RANK_ON_OFF = '2010304', // 启用/停用
  RANK_DELETE = '2010305', // 删除

  PART_TIME = '20104', // 兼岗管理
  PART_TIME_ADD = '2010401', // 新增兼岗
  PART_TIME_EDIT = '2010402', // 编辑
  PART_TIME_DELETE = '2010403', // 删除
  PART_TIME_START = '2010404', // 开始
  PART_TIME_STOP = '2010405', // 结束
  PART_TIME_LIST = '2010406', // 结束

  Leave = '301',
  LEAVE_MANAGE = '30101', // 请假管理
  LEAVE_MANAGE_ALL_APPLY = '3010102', // 全部申请
  LEAVE_MANAGE_ALL_APPLY_LIST = '301010201', // 查看
  LEAVE_MANAGE_AUDIT = '301010202', // 审批
  LEAVE_MANAGE_ADD_SIGN = '301010203', // 加签
  LEAVE_MANAGE_TRANSFER = '301010204', // 转交
  LEAVE_MANAGE_EDIT = '301010205', // 变更
  LEAVE_MANAGE_REVOKE = '301010206', // 撤销
  LEAVE_MANAGE_ALL_APPLY_EXPORT = '301010207', // 导出
  LEAVE_MANAGE_URGE = '301010208', // 催办
  LEAVE_MANAGE_ADD_ATTACH = '3010103', // 附件补交
  LEAVE_MANAGE_ADD_ATTACH_LIST = '301010301',
  LEAVE_MANAGE_ADD_ATTACH_AUDIT = '301010302',
  LEAVE_MANAGE_ADD_ATTACH_EXPORT = '301010303',

  LEAVE_BALANCE = '30102', // 假期余额
  LEAVE_BALANCE_LIST = '3010201', // 查看
  LEAVE_BALANCE_EXPORT = '3010202', // 导出
  LEAVE_BALANCE_INFO = '3010203', // 明细
  LEAVE_BALANCE_IMPORT = '3010204', // 导入

  LEAVE_OUT_OFFICIAL = '30103', // 公出
  LEAVE_OUT_OFFICIAL_ALL_APPLY = '3010302', // 公出-全部申请

  LEAVE_OUT_BUSINESS = '30104', // 出差
  LEAVE_OUT_BUSINESS_ALL_APPLY = '3010402', // 出差-全部申请

  SYSTEM = '401', //系统管理

  SYSTEM_LEAVE_PLAN = '40101', // 系统-假勤方案
  SYSTEM_LEAVE_PLAN_LIST = '4010101', // 系统-假勤方案,查看
  SYSTEM_LEAVE_PLAN_BIND = '4010102', // 系统-假勤方案,绑定员工假勤方案
  SYSTEM_LEAVE_PLAN_EDIT = '4010103', // 系统-假勤方案，编辑
  SYSTEM_LEAVE_PLAN_DELETE = '4010104', // 系统-假勤方案，删除

  USER_ROLE = '40102', // 角色权限,人员角色

  USER_ROLE_PEOPLE = '4010201', // 人员管理

  UR_PEOPLE_LIST = '401020101', // 查看
  UR_PEOPLE_ADD = '401020102', // 添加
  UR_PEOPLE_EDIT = '401020103', // 编辑
  UR_PEOPLE_DELETE = '401020104', // 删除

  USER_ROLE_MENU = '4010202', // 人员角色菜单

  UR_ROLE_LIST = '401020201', // 查看
  UR_ROLE_ADD = '401020202', // 添加
  UR_ROLE_EDIT = '401020203', // 编辑
  UR_ROLE_DELETE = '401020204', // 删除

  SYSTEM_CONFIG = '40103', // 系统配置
  SYSTEM_CONFIG_SPECIAL = '4010301', // 系统配置-特殊人员配置

  SYSTEM_LOG = '40104', // 日志
  SYSTEM_OPERATION_LOG = '4010401', // 操作日志

  PERFORMANCE = '501',
  PERFORMANCE_MANAGE = '50101', // 绩效管理
  PERFORMANCE_MANAGE_LIST = '5010101', // 绩效管理-查看列表
  PERFORMANCE_MANAGE_ADD = '5010102', // 新建绩效考核
  PERFORMANCE_MANAGE_EDIT = '5010103', // 编辑绩效考核

  PERFORMANCE_MANAGE_MEMBER = '5010104', // 被考核人
  PERFORMANCE_MANAGE_MEMBER_LIST = '501010401', //查看被考核人列表
  PERFORMANCE_MANAGE_MEMBER_ADD = '501010402', //添加被考核人
  PERFORMANCE_MANAGE_MEMBER_IMPORT = '501010403', // 导入被考核人
  PERFORMANCE_MANAGE_MEMBER_EXPORT = '501010404', // 导出
  PERFORMANCE_MANAGE_MEMBER_URGE = '501010405', //批量催办
  PERFORMANCE_MANAGE_MEMBER_EDIT = '501010406', // 编辑被考核人
  PERFORMANCE_MANAGE_MEMBER_DETAIL = '501010407', //被考核人详情查看
  PERFORMANCE_MANAGE_MEMBER_OPERATION = '501010408', // 被考核人详情所有操作
  PERFORMANCE_MANAGE_MEMBER_DELETE = '501010409', // 删除被考核人
  PERFORMANCE_MANAGE_MEMBER_GROUP_LIST = '501010410', // 查看考核组
  PERFORMANCE_MANAGE_MEMBER_GROUP_ADD = '501010411', // 新建考核组
  PERFORMANCE_MANAGE_MEMBER_GROUP_EDIT = '501010412', // 编辑考核组
  PERFORMANCE_MANAGE_MEMBER_GROUP_DELETE = '501010413', // 删除考核组

  PERFORMANCE_MANAGE_OPEN = '5010105', // 开启绩效考核
  PERFORMANCE_MANAGE_FINISH = '5010106', // 完成绩效考核
  PERFORMANCE_MANAGE_DELETE = '5010107', // 删除绩效考核

  PERFORMANCE_APPEAL = '50105', // 申诉审核
  PERFORMANCE_APPEAL_LIST = '5010501', // 查看列表
  PERFORMANCE_APPEAL_APPROVAL = '5010502', // 审核
  PERFORMANCE_MONTH = '50106', // 月度绩效

  ATTENDANCE = '601', //考勤管理
  ATTENDANCE_SHIFT = '60101', // 班次管理
  ATTENDANCE_SHIFT_ADD = '6010101', // 新增班次
  ATTENDANCE_SHIFT_EDIT = '6010102', // 编辑班次
  ATTENDANCE_SHIFT_ON_OFF = '6010103', // 启用/停用班次
  ATTENDANCE_SHIFT_DELETE = '6010104', // 删除班次
  ATTENDANCE_SHIFT_LIST = '6010105', // 查看班次

  ATTENDANCE_GROUP = '60102', // 考勤组管理
  ATTENDANCE_GROUP_ADD = '6010201', // 新增考勤组
  ATTENDANCE_GROUP_NO_NEED_PEOPLE = '6010202', // 无需考勤人员
  ATTENDANCE_GROUP_EDIT = '6010203', // 编辑考勤组
  ATTENDANCE_GROUP_ON_OFF = '6010204', // 启用/停用考勤组
  ATTENDANCE_GROUP_DELETE = '6010205', // 删除考勤组
  ATTENDANCE_GROUP_LIST = '6010206', // 查看考勤组

  ATTENDANCE_SCHEDULE = '60103', // 排班管理
  ATTENDANCE_SCHEDULE_EDIT = '6010301', // 编辑排班
  ATTENDANCE_SCHEDULE_IMPORT = '6010302', //导入排班
  ATTENDANCE_SCHEDULE_EXPORT = '6010303', // 导出排班
  ATTENDANCE_SCHEDULE_LIST = '6010304', // 查看排班

  ATTENDANCE_STAT = '60104', // 出勤统计
  ATTENDANCE_STAT_DAY = '6010401', // 每日统计
  ATTENDANCE_STAT_DAY_EXPORT = '601040101', // 导出每日统计
  ATTENDANCE_STAT_DAY_LIST = '601040102', // 查看每日统计
  ATTENDANCE_STAT_MONTH = '6010402', // 月度统计
  ATTENDANCE_STAT_MONTH_EXPORT = '601040201', // 导出月度统计
  ATTENDANCE_STAT_MONTH_LIST = '601040202', // 查看月度统计

  ATTENDANCE_OVERTIME = '60105', // 加班管理
  ATTENDANCE_OVERTIME_ALL_APPROVAL = '6010501', // 全部加班
  ATTENDANCE_OVERTIME_ALL_APPROVAL_EXPORT = '601050101', // 全部加班导出
}
export type IPermissionKV = Record<string | PermissionCode, IPermission>;
