import React from 'react';
import Empty from '@/components/Empty';
import { useSearchParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { Link } from '@arco-design/web-react';
import Device from '@/utils/device';
const isMobile = Device.isMobile();

const DingDingTransfer = React.memo(() => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect');
  const goOut = () => {
    if (redirectUrl) {
      if (!isMobile) {
        window.open(decodeURIComponent(redirectUrl), '_blank');
      }
    }
  };
  useMount(() => {
    if (!isMobile) {
      goOut();
    }
  });
  const onClick = () => {
    goOut();
  };
  return (
    <div style={{ height: '100vh' }}>
      {isMobile ? (
        <Empty
          type={'browser'}
          center
          text={'暂时还不支持移动端界面，请在pc端打开'}
        />
      ) : (
        <Empty
          type={'browser'}
          center
          bottom={
            <Link onClick={onClick} style={{ marginTop: 8 }}>
              点击再次打开
            </Link>
          }
        />
      )}
    </div>
  );
});
export default DingDingTransfer;
