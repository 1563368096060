import { observer } from 'mobx-react-lite';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkLogin, login } from '@/utils/auth';
import * as dd from 'dingtalk-jsapi';
import { useEffect, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import { paramObj } from '@/utils/tools';
import { apiDingdingLogin } from '@/api/system';
import Empty from '@/components/Empty';
import MobilePage from '@/components/MobilePage';
import { useOrg } from '@/utils/hooks/useOrg';

const XUNLEI_NETWORK_CORPID = 'ding41740805e448ca9135c2f4657eb6378f';
const XUNLEI_GROUP_CORPID = 'ding221b5262adf44bea35c2f4657eb6378f';

const MobileLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [errorText, setErrorText] = useState<string>();
  const { orgId } = useOrg();
  const navigateToHome = useMemoizedFn(() => {
    const redirectUrl = searchParams?.get('redirect');
    const defaultPath = `/org/${orgId}/`;
    try {
      const decodeUrl = redirectUrl
        ? decodeURIComponent(redirectUrl)
        : undefined;
      navigate(decodeUrl || defaultPath, {
        replace: true,
        state: { isAutoLogin: true },
      });
    } catch (e) {
      navigate(defaultPath, { replace: true, state: { isAutoLogin: true } });
    }
  });

  const tryDingDingInnerAppLogin = async () => {
    let corpId = searchParams?.get('corp_id') ?? '';
    console.log('corpId', corpId);
    const redirectUrl = searchParams?.get('redirect');
    console.log('redirectUrl', redirectUrl);
    console.log(location, decodeURIComponent(redirectUrl ?? ''));
    const urlCorpId =
      redirectUrl && paramObj(decodeURIComponent(redirectUrl))?.get('corp_id');

    if (urlCorpId) {
      corpId = urlCorpId;
    }
    console.log('tryDingDingInnerAppLogin1', corpId);
    if (dd.env.platform === 'notInDingTalk' || !corpId) {
      setErrorText('请在钉钉工作台打开');
      return;
    }
    const loginWithInnerApp = async (code: string) => {
      // 由于该应用挂载在迅雷网络下，并且服务端只存有迅雷集团的corpid，固需要转换为迅雷集团使登录成功
      const { token } = await apiDingdingLogin({
        code,
      });
      if (token) {
        login(token);
        navigateToHome();
      }
    };

    dd.ready(async function () {
      try {
        const res = await dd.runtime.permission.requestAuthCode({
          corpId:
            corpId === XUNLEI_NETWORK_CORPID ? XUNLEI_GROUP_CORPID : corpId,
        });
        loginWithInnerApp(res.code);
      } catch (error) {
        console.log(error);
        setErrorText('自动免登录未成功');
        // MToast.show('error', {
        //   content: `自动免登录未成功`,
        // });
        // MobileToast('error', {
        //   content: `自动免登录未成功，请尝试使用手机号登录: ${JSON.stringify(
        //     error
        //   )}`,
        // });
      }
    });
  };

  useEffect(() => {
    if (checkLogin()) {
      navigateToHome();
      return;
    }
    // console.log('window',window.location)
    tryDingDingInnerAppLogin();
  }, []);

  return (
    <MobilePage title={'登录'}>
      <div className={'ehr-mobile'}>
        {errorText ? (
          <div
            style={{ height: '90vh', display: 'flex', flexDirection: 'column' }}
          >
            <Empty type={'noExist'} center text={errorText} />
          </div>
        ) : null}
      </div>
    </MobilePage>
  );
};

export default observer(MobileLogin);
