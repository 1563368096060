import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { LocalStorageKey } from '@/constants/storage';
import Exception404 from '@/pages/exception/404';
import { useOrg } from '@/utils/hooks/useOrg';

interface IProps {}
/** 没有组织前缀的旧路由重定向 */
const OldRouteTransfer = React.memo((props: IProps) => {
  const [searchParams] = useSearchParams();
  /** 兼容旧通知url，先取orgId，没有再取localStorage的 */
  const localOrgId =
    searchParams.get('org') ||
    localStorage.getItem(LocalStorageKey.ORGANIZATION_ID);
  if (!localOrgId) {
    return <Exception404 />;
  }
  if (!window.location.pathname.startsWith('/org')) {
    return (
      <Navigate to={`/org/${localOrgId}${window.location.pathname}`} replace />
    );
  } else {
    return <Navigate to={`/org/${localOrgId}`} replace />;
  }
});
export default OldRouteTransfer;
