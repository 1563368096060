import axios from 'axios';

let oldHtml: string;
export const checkUpdate = () => {
  const id = Date.now() + '';

  axios(`/?t=${id}`).then((res) => {
    const htmlStr = res.data;
    if (!oldHtml) {
      oldHtml = htmlStr;
      return;
    }
    if (htmlStr !== oldHtml) {
      oldHtml = htmlStr;
      window.location.reload();
    }
  });
};
