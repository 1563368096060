import lazyLoad from '@/utils/lazyLoad';
import { IRoute } from '@/router/typings';
import UserIcon from '@/assets/icon_user.svg';
import PerformanceIcon from '@/assets/icon-performance.svg';
import OrganizationIcon from '@/assets/icon-organization.svg';
import LeaveIcon from '@/assets/icon_leave.svg';
import SystemIcon from '@/assets/icon-system.svg';
import { PermissionCode } from '@/utils/auth/config';
import { IconHome } from '@arco-design/web-react/icon';

const RankManagement = lazyLoad(
  () => import('@/pages/organization/RankManagement')
);
const JobManagement = lazyLoad(
  () => import('@/pages/organization/JobManagement')
);
const PartTime = lazyLoad(() => import('@/pages/organization/PartTime'));
const LeavePlan = lazyLoad(() => import('@/pages/system/LeavePlan'));
const LeaveDetail = lazyLoad(() => import('@/pages/leave/LeaveDetail'));
const OutBusiness = lazyLoad(() => import('@/pages/leave/OutBusiness'));
const OutBusinessDetail = lazyLoad(
  () => import('@/pages/leave/OutBusinessDetail')
);
const OutOfficial = lazyLoad(() => import('@/pages/leave/OutOfficial'));
const OutOfficialDetail = lazyLoad(
  () => import('@/pages/leave/OutOfficialDetail')
);
const LeaveBalance = lazyLoad(() => import('@/pages/leave/LeaveBalance'));
const LeaveManage = lazyLoad(() => import('@/pages/leave/LeaveManage'));

const ShiftManage = lazyLoad(() => import('@/pages/attendance/ShiftManage'));
const AttendGroupManage = lazyLoad(
  () => import('@/pages/attendance/AttendGroupManage')
);
const ScheduleManage = lazyLoad(
  () => import('@/pages/attendance/ScheduleManage')
);
const ScheduleDetail = lazyLoad(
  () => import('@/pages/attendance/ScheduleDetail')
);
const AttendStat = lazyLoad(() => import('@/pages/attendance/AttendStat'));

const MyAttendance = lazyLoad(() => import('@/pages/attendance/MyAttendance'));

const OvertimeManage = lazyLoad(() => import('@/pages/attendance/OvertimeManage'));
const OvertimeDetail = lazyLoad(() => import('@/pages/attendance/OvertimeManage/OvertimeDetail'));

const Department = lazyLoad(() => import('@/pages/organization/Department'));
const UserRole = lazyLoad(() => import('@/pages/system/UserRole'));
const People = lazyLoad(() => import('@/pages/system/UserRole/People'));
const Role = lazyLoad(() => import('@/pages/system/UserRole/Role'));
const SystemSetting = lazyLoad(() => import('@/pages/system/SystemSetting'));
const DataSetting = lazyLoad(() => import('@/pages/system/DataSetting'));
const DataSettingDetail = lazyLoad(
  () => import('@/pages/system/DataSettingDetail')
);
const Log = lazyLoad(() => import('@/pages/system/Log'));

export const UserRoleMenu = [
  {
    name: '人员管理',
    path: '/system/prole/people',
    component: People,
    ignore: true,
    permission: PermissionCode.USER_ROLE_PEOPLE,
  },
  {
    name: '角色管理',
    path: '/system/prole/role',
    component: Role,
    ignore: true,
    permission: PermissionCode.USER_ROLE_MENU,
  },
];

const baseRoutes: IRoute[] = [
  {
    name: '首页',
    path: '/home',
    icon: <IconHome />,
    component: lazyLoad(() => import('@/pages/home/Home')),
  },
  {
    name: '人事管理',
    path: '/people',
    icon: <UserIcon className={'arco-icon'} />,
    children: [
      {
        name: '花名册',
        path: '/people/roster',
        component: lazyLoad(() => import('@/pages/people/Roster')),
        permission: PermissionCode.ROSTER,
      },
      {
        name: '花名册-在职信息采集详情',
        path: '/people/roster/collect/:id',
        component: lazyLoad(
          () => import('@/pages/people/RosterCollectApplyDetail')
        ),
        ignore: true,
      },
      {
        name: '入职管理',
        path: '/people/entry',
        component: lazyLoad(() => import('@/pages/people/Entry')),
        permission: PermissionCode.ENTRY,
      },
      {
        name: '入职准备-待办',
        path: '/people/entry/todo',
        component: lazyLoad(() => import('@/pages/people/EntryTodo')),
        ignore: true,
      },
      {
        name: '转正管理',
        path: '/people/regular',
        component: lazyLoad(() => import('@/pages/people/Regular')),
        permission: PermissionCode.REGULAR,
      },
      {
        name: '转正管理-转正审批详情',
        path: '/people/regular/apply/:id',
        component: lazyLoad(() => import('@/pages/people/RegularApplyDetail')),
        ignore: true,
      },
      {
        name: '调动管理',
        path: '/people/transfer',
        component: lazyLoad(() => import('@/pages/people/Transfer')),
        permission: PermissionCode.TRANSFER,
      },
      {
        name: '调动管理-调动审批详情',
        path: '/people/transfer/apply/:id',
        component: lazyLoad(() => import('@/pages/people/TransferApplyDetail')),
        ignore: true,
      },
      {
        name: '离职管理',
        path: '/people/resign',
        permission: PermissionCode.RESIGN,
        component: lazyLoad(() => import('@/pages/people/Resign')),
      },
      {
        name: '离职管理-离职审批详情',
        path: '/people/resign/apply/:id',
        component: lazyLoad(() => import('@/pages/people/ResignApplyDetail')),
        ignore: true,
      },
      {
        name: '人事申请',
        path: '/people/staff-apply',
        component: lazyLoad(() => import('@/pages/people/StaffApply')),
      },
      {
        name: '人事申请-离职审批详情',
        path: '/people/staff-apply/resign/:id',
        component: lazyLoad(() => import('@/pages/people/ResignApplyDetail')),
        ignore: true,
      },
      {
        name: '人事申请-在职信息采集详情',
        path: '/people/staff-apply/collect/:id',
        component: lazyLoad(
          () => import('@/pages/people/RosterCollectApplyDetail')
        ),
        ignore: true,
      },
      {
        name: '人事申请-转正详情',
        path: '/people/staff-apply/regular/:id',
        component: lazyLoad(() => import('@/pages/people/RegularApplyDetail')),
        ignore: true,
      },
      {
        name: '人事申请-调动详情',
        path: '/people/staff-apply/transfer/:id',
        component: lazyLoad(() => import('@/pages/people/TransferApplyDetail')),
        ignore: true,
      },
      {
        name: '黑名单管理',
        path: '/people/blacklist',
        component: lazyLoad(() => import('@/pages/people/BlackList')),
        permission: PermissionCode.BLACKLIST,
      },
    ],
  },
  {
    name: '绩效管理',
    path: '/performance',
    icon: <PerformanceIcon className={'arco-icon'} />,
    children: [
      {
        name: '绩效管理',
        path: '/performance/management',
        component: lazyLoad(
          () => import('@/pages/performance/PerformanceManage')
        ),
        permission: PermissionCode.PERFORMANCE_MANAGE,
      },
      {
        name: '绩效管理-被考核人',
        path: '/performance/management/members',
        ignore: true,
        component: lazyLoad(
          () => import('@/pages/performance/PerformanceMember')
        ),
        permission: PermissionCode.PERFORMANCE_MANAGE_MEMBER,
      },
      {
        name: '绩效首页',
        path: '/performance/home',
        component: lazyLoad(
          () => import('@/pages/performance/PerformanceHome')
        ),
      },
      {
        name: '绩效首页-已处理待办',
        path: '/performance/home/finished-todo',
        ignore: true,
        component: lazyLoad(
          () => import('@/pages/performance/PerformanceFinishedTodo')
        ),
      },
      {
        name: '绩效首页-绩效待办',
        path: '/performance/home/todo',
        ignore: true,
        component: lazyLoad(
          () => import('@/pages/performance/PerformanceTodo')
        ),
      },
      {
        name: '绩效首页-绩效详情',
        path: '/performance/home/detail',
        ignore: true,
        component: lazyLoad(
          () => import('@/pages/performance/MemberPerformanceDetail')
        ),
      },
      {
        name: '我的绩效',
        path: '/performance/my',
        component: lazyLoad(() => import('@/pages/performance/MyPerformance')),
      },
      {
        name: '绩效详情',
        path: '/performance/my/detail',
        ignore: true,
        component: lazyLoad(
          () => import('@/pages/performance/MemberPerformanceDetail')
        ),
      },
      {
        name: '我的申诉',
        path: '/performance/my-appeal',
        component: lazyLoad(
          () => import('@/pages/performance/PerformanceMyAppeal')
        ),
      },
      {
        name: '申诉审核',
        path: '/performance/appeal-approval',
        component: lazyLoad(
          () => import('@/pages/performance/PerformanceAppealApproval')
        ),
        permission: PermissionCode.PERFORMANCE_APPEAL,
      },
      {
        name: '申诉审核-绩效详情',
        path: '/performance/appeal-approval/performance-detail',
        ignore: true,
        component: lazyLoad(
          () => import('@/pages/performance/MemberPerformanceDetail')
        ),
        permission: PermissionCode.PERFORMANCE_APPEAL,
      },
      {
        name: '月度绩效',
        path: '/performance/month',
        component: lazyLoad(
          () => import('@/pages/performance/MonthPerformance')
        ),
        permission: PermissionCode.PERFORMANCE_MONTH,
      },
      {
        name: '月度绩效-绩效详情',
        path: '/performance/month/detail',
        component: lazyLoad(
          () => import('@/pages/performance/MonthPerformanceDetail')
        ),
        ignore: true,
        // permission: PermissionCode.PERFORMANCE_APPEAL,
      },
    ],
  },

  {
    name: '组织管理',
    path: '/organization',
    icon: <OrganizationIcon className={'arco-icon'} />,
    permission: PermissionCode.ORG,
    children: [
      {
        name: '部门管理',
        path: '/organization/department',
        component: Department,
        permission: PermissionCode.DEPARTMENT,
        icon: '',
      },
      {
        name: '岗位管理',
        path: '/organization/job',
        component: JobManagement,
        permission: PermissionCode.JOB,
        icon: '',
      },
      {
        name: '职级管理',
        path: '/organization/rank',
        component: RankManagement,
        permission: PermissionCode.RANK,
        icon: '',
      },
      {
        name: '兼岗管理',
        path: '/organization/part-time',
        component: PartTime,
        icon: '',
        permission: PermissionCode.PART_TIME,
      },
    ],
  },
  {
    name: '假勤管理',
    path: '/leave',
    icon: <LeaveIcon className={'arco-icon'} />,
    children: [
      {
        name: '假期余额',
        path: '/leave/balance',
        component: LeaveBalance,
        permission: PermissionCode.LEAVE_BALANCE,
        icon: '',
      },
      {
        name: '请假管理',
        path: '/leave/manage',
        component: LeaveManage,
        icon: '',
      },
      {
        name: '请假审批详情',
        path: '/leave/manage/detail/:id',
        component: LeaveDetail,
        ignore: true,
        icon: '',
      },
      {
        name: '公出',
        path: '/leave/out-official',
        component: OutOfficial,
        icon: '',
      },
      {
        name: '公出审批详情',
        path: '/leave/out-official/detail/:id',
        component: OutOfficialDetail,
        ignore: true,
        icon: '',
      },
      {
        name: '出差',
        path: '/leave/out-business',
        component: OutBusiness,
        icon: '',
      },
      {
        name: '出差审批详情',
        path: '/leave/out-business/detail/:id',
        component: OutBusinessDetail,
        ignore: true,
        icon: '',
      },
    ],
  },
  {
    name: '考勤管理',
    path: '/attendance',
    icon: <LeaveIcon className={'arco-icon'} />,
    children: [
      {
        name: '班次管理',
        path: '/attendance/shift',
        component: ShiftManage,
        permission: PermissionCode.ATTENDANCE_SHIFT,
      },
      {
        name: '考勤组管理',
        path: '/attendance/group',
        component: AttendGroupManage,
        permission: PermissionCode.ATTENDANCE_GROUP,
      },
      {
        name: '排班管理',
        path: '/attendance/schedule',
        component: ScheduleManage,
        permission: PermissionCode.ATTENDANCE_SCHEDULE,
      },
      {
        name: '编辑排班',
        path: '/attendance/schedule/:groupId',
        component: ScheduleDetail,
        permission: PermissionCode.ATTENDANCE_SCHEDULE_EDIT,
        ignore: true,
      },
      {
        name: '出勤统计',
        path: '/attendance/stat',
        permission: PermissionCode.ATTENDANCE_STAT,
        component: AttendStat,
      },
      {
        name: '我的考勤',
        path: '/attendance/my-attendance',
        component: MyAttendance,
      },
      {
        name: '加班管理',
        path: '/attendance/overtime',
        component: OvertimeManage,
      },
      {
        name: '加班审批详情',
        path: '/attendance/overtime/detail/:id',
        component: OvertimeDetail,
        ignore: true,
        icon: '',
      },
    ],
  },
  {
    name: '系统管理',
    path: '/system',
    icon: <SystemIcon className={'arco-icon'} />,
    permission: PermissionCode.SYSTEM,
    children: [
      {
        name: '假勤方案',
        path: '/system/leave-plan',
        component: LeavePlan,
        permission: PermissionCode.SYSTEM_LEAVE_PLAN,
        icon: '',
      },
      {
        name: '人员角色',
        path: '/system/prole',
        component: UserRole,
        icon: '',
        children: UserRoleMenu,
        permission: PermissionCode.USER_ROLE,
      },
      {
        name: '系统配置',
        path: '/system/setting',
        component: SystemSetting,
        permission: PermissionCode.SYSTEM_CONFIG,
      },
      {
        name: '数组配置',
        path: '/system/data',
        component: DataSetting,
        permission: PermissionCode.SYSTEM_CONFIG,
      },
      {
        name: '数组配置',
        path: '/system/data/:dataCode',
        component: DataSettingDetail,
        ignore: true,
      },
      {
        name: '日志',
        path: '/system/log',
        component: Log,
        permission: PermissionCode.SYSTEM_LOG,
      },
    ],
  },
];
export default baseRoutes;
