import React from 'react';
import EmptyNoPermission from '@/assets/empty/empty-no-permission.png';
import styles from '../index.module.less';
import Device from '@/utils/device';
import { logout } from '@/utils/auth';
const ExceptionNoPermission = () => {
  const onLogout = async () => {
    logout(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img src={EmptyNoPermission} style={{ width: 160, height: 160 }} />
        <div className={styles.text}>无权限</div>
        {Device.isMobile() && (
          <a className={styles.btn} onClick={onLogout}>
            重新登录
          </a>
        )}
      </div>
    </div>
  );
};

export default ExceptionNoPermission;
