import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from '@arco-design/web-react';
import { BrowserRouter } from 'react-router-dom';
import { StoreContext } from './store';
import MainRouter from '@/router';
import { store } from './store';
import { observer } from 'mobx-react-lite';
import { ComponentConfig } from '@arco-design/web-react/es/ConfigProvider/interface';
import { useCheckUpdate } from '@/utils/hooks/useCheckUpdate';
// import VConsole from 'vconsole';
// import envInfo from '../env.json';
import '@/style/global.less';
import Device from '@/utils/device';
import setRootPixel from '@arco-design/mobile-react/tools/flexible';
// if (envInfo.env !== 'prod') new VConsole();

const isMobile = Device.isMobile();

const App = () => {
  const componentConfig: ComponentConfig = {
    Card: {
      bordered: false,
    },
    List: {
      bordered: false,
    },
    Table: {
      border: false,
    },
    Form: {
      validateMessages: {
        required: (_, { label }) => `请填写 ${label}`,
      },
    },
    Trigger: {
      getPopupContainer: () => document.body,
      updateOnScroll: true,
    },
    Modal: {
      autoFocus: false,
    },
    Select: {
      filterOption: (inputValue, option) => {
        return (
          `${option.props.children}`
            ?.toLowerCase()
            ?.indexOf(inputValue.toLowerCase()) >= 0
        );
      },
    },
  };
  const [styleLoading, setStyleLoading] = useState(true);
  useLayoutEffect(() => {
    if (isMobile) {
      import('@/style/mobile').then(() => {
        setRootPixel(50, 375);
        setStyleLoading(false);
      });
    } else {
      import('@/style/pc').then(() => {
        setStyleLoading(false);
      });
    }
  }, [isMobile]);
  useCheckUpdate();
  return (
    <BrowserRouter>
      <ConfigProvider componentConfig={componentConfig}>
        <StoreContext.Provider value={store}>
          {styleLoading ? null : <MainRouter />}
        </StoreContext.Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
};
const Root = observer(App);
ReactDOM.render(<Root />, document.getElementById('root'));
