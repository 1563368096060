import { Button, Form, Input, Message } from '@arco-design/web-react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cs from 'classnames';
import ReSendcode from './components/ReSendcode';
import { FieldError } from '@arco-design/web-react/es/Form/interface';
import { checkLogin, login } from '@/utils/auth';

import LogoImg from '@/assets/logo.png';
import LeftIcon from '@/assets/icon_left.svg';

import styles from './index.module.less';
import React, { useEffect, useMemo } from 'react';
import { useMemoizedFn } from 'ahooks';
import { apiLogin, apiSendSmsCode } from '@/api/system';
import { LocalStorageKey } from '@/constants/storage';
import { useOrg } from '@/utils/hooks/useOrg';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';

const { useForm } = Form;
const Login = () => {
  const [loginForm] = useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { orgId } = useOrg();
  const store = useLocalObservable(() => ({
    mobile: '',
    sendingCode: false,
    enableSendcode: false,
    loging: false,
    enableLogin: false,
    step: 1,
    now: 0,
    timestamp: 0,
    setStep(step: number) {
      this.step = step;
    },
    setMobile(mobile: string) {
      this.mobile = mobile;
    },
    setSendingCode(status: boolean) {
      this.sendingCode = status;
    },
    setEnableSendcode(status: boolean) {
      this.enableSendcode = status;
    },
    setLoging(status: boolean) {
      this.loging = status;
    },
    setEnableLogin(status: boolean) {
      this.enableLogin = status;
    },
    setTimestamp(timestamp: number) {
      this.timestamp = timestamp;
    },
    setNow(now: number) {
      this.now = now;
    },
  }));
  const navigateToHome = useMemoizedFn(() => {
    const redirectUrl = searchParams?.get('redirect');
    const defaultPath = `/org/${orgId}/`;
    try {
      const decodeUrl = redirectUrl
        ? decodeURIComponent(redirectUrl)
        : undefined;
      navigate(decodeUrl || defaultPath, { replace: true });
    } catch (e) {
      navigate(defaultPath, { replace: true });
    }
  });
  useEffect(() => {
    if (checkLogin()) {
      navigateToHome();
    }
  }, []);
  const onSendCode = async () => {
    store.setSendingCode(true);
    const { timestamp, now } = await apiSendSmsCode({ mobile: store.mobile });
    store.setSendingCode(false);
    store.setTimestamp(timestamp * 1000);
    store.setNow(now ? now * 1000 : Date.now());
    store.setStep(2);
  };

  const onSubmitFaild = (errors: { [key: string]: FieldError }) => {
    console.log('Failed:', errors);
  };

  const onMobileChange = (value: string) => {
    const v = value ? value.trim() : '';
    store.setMobile(v);
    const reg = /^(?:(?:\+|00)86)?1\d{10}$/;
    if (reg.test(v)) {
      store.setEnableSendcode(true);
      return;
    }
    store.setEnableSendcode(false);
  };

  const onSubmit = async (values: { code: string }) => {
    store.setLoging(true);
    if (!orgId) {
      Message.error('没有组织id');
    }
    try {
      const { token } = await apiLogin({
        orgId: orgId,
        mobile: store.mobile,
        code: values.code,
      });
      store.setLoging(false);
      if (token) {
        login(token);
        navigateToHome();
        Message.success('登录成功');
      }
    } catch (error) {
      console.log(error);
      store.setLoging(false);
    }
  };

  const onCodeChange = (value: string) => {
    const reg = /^\d{6}$/;
    if (reg.test(value)) {
      store.setEnableLogin(true);
      return;
    }
    store.setEnableLogin(false);
  };

  const renderHeader = (
    <div className={styles.headerBox}>
      <div className={styles.header}>
        {store.step === 1 && (
          <div className={styles.headerTitle}>欢迎使用，请登录</div>
        )}
        {store.step === 2 && (
          <div className={cs(styles.headerTitle, styles.headerLeft)}>
            <span className={styles.leftIcon} onClick={() => store.setStep(1)}>
              <LeftIcon />
            </span>
            验证手机号
          </div>
        )}
        {/* <XunLeiIcon /> */}
      </div>
      {store.step === 2 && (
        <div className={styles.tip}>
          请输入发送至 {store.mobile}{' '}
          的6位验证码，有效期十分钟。如未收到，请尝试重新获取验证码。
        </div>
      )}
    </div>
  );

  const renderMobileForm = (
    <Form
      form={loginForm}
      layout="vertical"
      onSubmit={onSendCode}
      onSubmitFailed={onSubmitFaild}
    >
      <Form.Item
        field="mobile"
        rules={[{ required: true, message: '请输入手机号' }]}
      >
        <Input
          placeholder="请输入手机号"
          size="large"
          onChange={onMobileChange}
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          long
          size="large"
          type="primary"
          htmlType="submit"
          loading={store.sendingCode}
          disabled={!store.enableSendcode}
        >
          下一步
        </Button>
      </Form.Item>
    </Form>
  );

  const renderFillCodeForm = (
    <Form layout="vertical" onSubmit={onSubmit} onSubmitFailed={onSubmitFaild}>
      <Form.Item
        field="code"
        rules={[{ required: true, message: '请输入验证码' }]}
        extra={
          <ReSendcode
            now={store.now}
            date={store.timestamp}
            onSendCode={onSendCode}
          />
        }
      >
        <Input
          placeholder="请输入验证码"
          size="large"
          onChange={onCodeChange}
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          long
          size="large"
          type="primary"
          htmlType="submit"
          loading={store.loging}
          disabled={!store.enableLogin}
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div className={styles.login}>
      <Helmet>
        <title>EHR</title>
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      </Helmet>
      <img className={styles.logo} src={LogoImg} />
      <div className={styles.loginBox}>
        {renderHeader}
        {store.step === 1 && renderMobileForm}
        {store.step === 2 && renderFillCodeForm}
      </div>
    </div>
  );
};

export default observer(Login);
