import { ComponentType } from 'react';
// @ts-ignore
import loadable from '@loadable/component';
const lazyLoad = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): any => {
  const Component = loadable(factory);
  Component.preload = factory;

  return Component;
};

export default lazyLoad;
