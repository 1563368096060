import { store, useStore } from '@/store';
import { useEffect, useMemo, useState } from 'react';
import { LocalStorageKey } from '@/constants/storage';
import { IPermissionKV, PermissionCode } from '@/utils/auth/config';
import { CookiesKey } from '@/constants/cookies';
import Cookies from 'js-cookie';
import axios from 'axios';
import Pattern from '@/constants/pattern';

export const checkPermission = (
  permissionCode: PermissionCode,
  permissionKV: IPermissionKV
) => {
  return !!permissionKV[permissionCode];
};

export const useHasPermission = (
  permission: PermissionCode | PermissionCode[] = []
) => {
  const { permissionKV } = useStore('user');

  return useMemo(() => {
    if (Array.isArray(permission)) {
      return permission.every((item) => checkPermission(item, permissionKV));
    } else {
      return checkPermission(permission, permissionKV);
    }
  }, [permissionKV, permission]);
};

export const checkLogin = () => {
  return !!localStorage.getItem(LocalStorageKey.TOKEN);
};

export const useIsLoginAsync = () => {
  const [isLogin, setIsLogin] = useState(() => checkLogin());

  useEffect(() => {
    if (!checkLogin()) {
      setIsLogin(false);
      return;
    }
    /** 有token，不代表已登录，有可能登录已过期 */
    const orgId = localStorage.getItem(LocalStorageKey.ORGANIZATION_ID) || '';
    const token = localStorage.getItem(LocalStorageKey.TOKEN) || '';
    if (!orgId) {
      setIsLogin(false);
    }
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      h_mb_token: token,
      organization_id: orgId,
    };
    axios
      .get('/hr/system/menu/permission', {
        headers,
        withCredentials: true,
      })
      .then(() => {
        setIsLogin(true);
      })
      .catch(() => {
        setIsLogin(false);
      });
  }, []);
  return [isLogin];
};

export const redirectToLogin = (redirect = true) => {
  const orgId =
    location.pathname?.match(Pattern.ORG_PREFIX)?.[1] ||
    localStorage.getItem(LocalStorageKey.ORGANIZATION_ID);
  if (redirect) {
    const redirectUrl = encodeURIComponent(
      `${location.pathname}${location.search}`
    );
    // window.location.href = `/login?redirect=${redirectUrl}`;
    if (!window.location.pathname.includes('/login')) {
      window.location.replace(`/org/${orgId}/login?redirect=${redirectUrl}`);
    }
  } else {
    window.location.replace(`/org/${orgId}/login`);
    // window.location.href = `/login`;
  }
};
export const logout = (redirect = true) => {
  const userStore = store.user;
  userStore.clear();
  localStorage.removeItem(LocalStorageKey.TOKEN);
  Cookies.remove(CookiesKey.TOKEN);
  // document.cookie =
  //   CookiesKey.TOKEN + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  redirectToLogin(redirect);
};

export const login = (hMbToken: string) => {
  localStorage.setItem(LocalStorageKey.TOKEN, hMbToken);
  const { hostname } = window.location;
  Cookies.set(CookiesKey.TOKEN, hMbToken, {
    domain: hostname,
    expires: 31,
  });
};
