import axios from '@/http/axios';
import { AxiosRequestConfig } from 'axios';
import { Message } from '@arco-design/web-react';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { IResponseData } from '@/http/config';
import MToast from '@/utils/mobile/toast';
import Device from '@/utils/device';
const isMobile = Device.isMobile();
/**
 * 封装http请求
 */
class Http {
  async get<P = Record<string, any> | undefined, R = any>(
    url: string,
    params?: P,
    config: AxiosRequestConfig = {},
    option = {
      showError: true,
    }
  ): Promise<R> {
    const { showError } = option;
    const axiosConfig: AxiosRequestConfig = {
      params: this.decamelizeObjectKey(params),
      ...config,
    };
    try {
      const res = await axios.get<IResponseData<R>>(url, axiosConfig);

      return this.camelizeKeysObjectKey(res.data) as R;
    } catch (e: any) {
      if (showError && e?.message) {
        if (isMobile) {
          MToast.show('error', {
            content: e?.message,
          });
        } else {
          Message.error(e?.message);
        }
      }
      throw e;
    }
  }
  async post<P = Record<string, any> | undefined, R = any>(
    url: string,
    data?: P,
    config: AxiosRequestConfig = {},
    option = {
      showError: true,
    }
  ): Promise<R> {
    const { showError = true } = option;
    try {
      const res = await axios.post<IResponseData<R>>(
        url,
        this.decamelizeObjectKey(data),
        config
      );
      return this.camelizeKeysObjectKey(res.data) as R;
    } catch (e: any) {
      if (showError && e?.message) {
        if (isMobile) {
          MToast.show('error', {
            content: e?.message,
          });
        } else {
          Message.error(e?.message);
        }
      }
      throw e;
    }
  }
  private camelizeKeysObjectKey = (data: any) => {
    if (Object.prototype.toString.call(data) === '[object Object]') {
      return camelizeKeys(data);
    }
    return data;
  };
  private decamelizeObjectKey = (data: any) => {
    if (!data) {
      return data;
    }
    return data instanceof FormData
      ? data
      : decamelizeKeys({
          ...data,
        });
  };
}

export default new Http();
