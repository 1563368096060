import { useEffect, useState } from 'react';
import { checkUpdate } from '@/utils/checkUpdate';

export const useCheckUpdate = () => {
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    const callback = () => {
      if (document.visibilityState === 'visible') {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };
    document.addEventListener('visibilitychange', callback);
    return () => {
      document.removeEventListener('visibilitychange', callback);
    };
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timer | null = null;
    if (visibility) {
      checkUpdate();
      timer = setInterval(() => {
        checkUpdate();
      }, 5 * 60 * 1000);
    }
    return () => {
      timer && clearInterval(timer);
    };
  }, [visibility]);
};
