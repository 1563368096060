import React, { useState, useRef, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Spin, Watermark } from '@arco-design/web-react';
import cs from 'classnames';
import { observer } from 'mobx-react-lite';
import { IconMenuFold, IconMenuUnfold } from '@arco-design/web-react/icon';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import { IRoute } from '@/router/typings';
import getUrlParams from './utils/getUrlParams';
import { useStore } from './store';
import styles from './style/layout.module.less';
import { transRoutePathToKey, useAuthRoute } from '@/router';
import { useMemoizedFn } from 'ahooks';
import ProgressBar from '@/utils/progressBar';
import settings from './settings.json';
import { checkLogin, redirectToLogin } from '@/utils/auth';
import { useOrg } from '@/utils/hooks/useOrg';
import { Helmet } from 'react-helmet';
const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

const Sider = Layout.Sider;
const Content = Layout.Content;

const PageLayout = () => {
  const urlParams = getUrlParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { userLoading, queryUserInfo, member, mobileTail } = useStore('user');
  const { queryCommonConfig, commonConfig } = useStore('systemConfig');
  const pathname = location.pathname;
  const { orgId } = useOrg();
  const currentComponent = transRoutePathToKey(pathname);
  const {
    permissionRoutes: routes,
    defaultRoutePath: defaultRoute,
    dpMenuRoutes,
  } = useAuthRoute();
  const defaultRouteKey = currentComponent || defaultRoute;
  const defaultSelectedKeys = [transRoutePathToKey(defaultRouteKey)];

  const paths = defaultRouteKey.split('/');
  const defaultOpenKeys = paths.slice(0, paths.length - 1);
  const [breadcrumb, setBreadCrumb] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] =
    useState<string[]>(defaultSelectedKeys);

  const [openKeys, setOpenKeys] = useState<string[]>(defaultOpenKeys);
  const routeMap = useRef<Map<string, string[]>>(new Map());
  const menuMap = useRef<
    Map<string, { menuItem?: boolean; subMenu?: boolean }>
  >(new Map());

  const navbarHeight = 60;
  const menuWidth = collapsed ? 48 : settings?.menuWidth;

  const showNavbar = settings?.navbar && urlParams.navbar !== false;
  const showMenu = settings?.menu && urlParams.menu !== false;
  const showFooter = settings?.footer && urlParams.footer !== false;

  useEffect(() => {
    const init = async () => {
      await queryUserInfo();
      queryCommonConfig();
    };
    if (checkLogin()) {
      init();
    } else if (window.location.pathname.replace(/\//g, '') !== 'login') {
      redirectToLogin();
    }
  }, []);

  function renderRoutes() {
    routeMap.current.clear();
    return function travel(
      _routes: IRoute[],
      level: number,
      parentNames: string[] = []
    ) {
      return _routes.map((route) => {
        const { breadcrumb = true, ignore } = route;
        const routeKey = transRoutePathToKey(route.path);
        const IconCom = route.icon || <div className={'hr-menu-icon-empty'} />;
        const titleDom = (
          <>
            {IconCom} {route.name}
          </>
        );
        routeMap.current.set(
          routeKey,
          breadcrumb ? [...parentNames, route.name] : []
        );

        const visibleChildren = (route.children || []).filter((child) => {
          const { ignore, breadcrumb = true } = child;
          if (ignore || route.ignore) {
            routeMap.current.set(
              routeKey,
              breadcrumb ? [...parentNames, route.name, child.name] : []
            );
          }

          return !ignore;
        });

        if (ignore) {
          return '';
        }
        if (visibleChildren.length) {
          menuMap.current.set(routeKey, { subMenu: true });
          return (
            <SubMenu key={routeKey} title={titleDom}>
              {travel(visibleChildren, level + 1, [...parentNames, route.name])}
            </SubMenu>
          );
        }
        menuMap.current.set(routeKey, { menuItem: true });
        return (
          <MenuItem
            key={routeKey}
            style={{ fontWeight: level === 1 ? 500 : 400 }}
          >
            {titleDom}
          </MenuItem>
        );
      });
    };
  }

  async function onClickMenuItem(key: string) {
    const currentRoute = dpMenuRoutes.find((r) => r.path === key);

    const component = currentRoute?.component;
    if (!orgId) {
      return;
    }
    try {
      ProgressBar.show();
      await component?.preload?.();
      if (currentRoute?.path) {
        navigate(currentRoute?.path);
      }
      ProgressBar.hide();
    } catch (e) {
      console.error(e);
      window.location.reload();
    }
  }

  function toggleCollapse() {
    setCollapsed((collapsed) => !collapsed);
  }

  const paddingLeft = showMenu ? { paddingLeft: menuWidth } : {};
  const paddingTop = showNavbar ? { paddingTop: navbarHeight } : {};
  const paddingStyle = { ...paddingLeft, ...paddingTop };

  const updateMenuStatus = useMemoizedFn(() => {
    const curRouteKey = transRoutePathToKey(pathname);
    const pathKeys = curRouteKey.split('/');
    const newSelectedKeys: string[] = [];
    const newOpenKeys: string[] = [...openKeys];
    while (pathKeys.length > 0) {
      const menuKey = pathKeys.join('/');
      const menuType = menuMap.current.get(menuKey);
      if (menuType && menuType.menuItem) {
        newSelectedKeys.push(menuKey);
      }
      if (menuType && menuType.subMenu && !openKeys.includes(menuKey)) {
        newOpenKeys.push(menuKey);
      }
      pathKeys.pop();
    }
    setSelectedKeys(newSelectedKeys);
    setOpenKeys(newOpenKeys);
  });

  const MenuList = renderRoutes()(routes, 1);
  useEffect(() => {
    /** route和path更新时，更新menu状态 */
    const routeConfig = routeMap.current.get(transRoutePathToKey(pathname));
    setBreadCrumb(routeConfig || []);
    Promise.resolve().then(() => {
      updateMenuStatus();
    });
  }, [pathname, routes]);

  return (
    <Layout className={styles.layout}>
      <Helmet>
        <title>EHR</title>
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      </Helmet>
      <div
        className={cs(styles['layout-navbar'], {
          [styles['layout-navbar-hidden']]: !showNavbar,
        })}
      >
        <Navbar />
      </div>
      {userLoading ? (
        <Spin className={styles.spin} size={24} />
      ) : (
        <Layout>
          {showMenu && (
            <Sider
              className={styles['layout-sider']}
              width={menuWidth}
              collapsed={collapsed}
              onCollapse={setCollapsed}
              trigger={null}
              collapsible
              breakpoint="xl"
              style={paddingTop}
            >
              <div className={styles['menu-wrapper']}>
                <Menu
                  collapse={collapsed}
                  onClickMenuItem={onClickMenuItem}
                  selectedKeys={selectedKeys}
                  openKeys={openKeys}
                  onClickSubMenu={(_, openKeys) => setOpenKeys(openKeys)}
                >
                  {MenuList}
                </Menu>
              </div>
              <div className={styles['collapse-btn']} onClick={toggleCollapse}>
                {collapsed ? <IconMenuUnfold /> : <IconMenuFold />}
              </div>
            </Sider>
          )}

          <Layout className={styles['layout-content']} style={paddingStyle}>
            <Content className={styles['layout-content-wrapper']}>
              <Outlet />
              {commonConfig?.watermark?.enable && member?.name && (
                <Watermark
                  getContainer={() =>
                    document.querySelector('.arco-layout-content')!
                  }
                  zIndex={1}
                  content={member?.name + mobileTail}
                  fontStyle={{
                    color: 'rgba(0,0,0,0.04)',
                  }}
                />
              )}
            </Content>

            {showFooter && <Footer />}
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};

export default observer(PageLayout);
