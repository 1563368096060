import React, { useContext } from 'react';
import User from './modules/user';
import SystemConfig from '@/store/modules/systemConfig';

const store = {
  user: new User(),
  systemConfig: new SystemConfig(),
} as const;
const StoreContext = React.createContext(store);

const useStore = <T extends keyof typeof store>(storeKey: T) => {
  const store = useContext(StoreContext);
  return store[storeKey];
};

export { store, StoreContext, useStore };
