import { memo, useEffect, useState } from 'react';
import { useCountDown } from 'ahooks';
import cs from 'classnames';
import styles from './index.module.less';

interface IResedcodeProps {
  date: number;
  now: number;
  onSendCode?: () => void;
  intervalSecond?: number;
}

const ReSendcode = (props: IResedcodeProps) => {
  const { date, now, onSendCode, intervalSecond = 60000 } = props;
  const [targetDate, setTargetDate] = useState<number>();

  useEffect(() => {
    const timestamp =
      now - date ? intervalSecond - (now - date) : intervalSecond;
    setTargetDate(Date.now() + timestamp);
  }, [date, intervalSecond, now]);

  const [countdown] = useCountDown({
    targetDate,
  });

  const onClickSend = () => {
    countdown <= 0 && onSendCode && onSendCode();
  };

  const renderCount = () => {
    return countdown > 0
      ? `重新获取验证码(${Math.round(countdown / 1000)}s)`
      : '重新获取验证码';
  };

  return (
    <div
      className={cs(styles.resendCode, { [styles.disabled]: countdown !== 0 })}
      onClick={onClickSend}
    >
      {renderCount()}
    </div>
  );
};

ReSendcode.defaultProps = {
  date: 0,
  now: 0,
  onSendCode: () => null,
  intervalSecond: 60000, // 间隔可以再次发送的毫秒数
};

export default memo(ReSendcode);
