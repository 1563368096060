import React from 'react';
import Empty404Img from '@/assets/empty/empty-404.png';
import styles from '../index.module.less';
const Exception404 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img src={Empty404Img} style={{ width: 160, height: 160 }} />
        <div className={styles.text}>页面未找到</div>
      </div>
    </div>
  );
};

export default Exception404;
