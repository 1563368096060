import { useParams } from 'react-router-dom';
import Pattern from '@/constants/pattern';

/** 获取路由路径上的组织id */
export const useOrg = () => {
  let { orgId } = useParams<{ orgId: string }>();
  if (orgId && !Pattern.ORG_ID.test(orgId)) {
    orgId = undefined;
  }
  return {
    orgId: orgId,
  };
};
export const getOrg = () => {
  let orgId = location.pathname?.match(Pattern.ORG_PREFIX)?.[1];
  if (orgId && !Pattern.ORG_ID.test(orgId)) {
    orgId = undefined;
  }
  return {
    orgId: orgId,
  };
};
